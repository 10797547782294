import { Asset, AssetFile } from 'contentful';
import Head from 'next/head';

import {
  getJsonLDsForSchema,
  getWebsiteJsonLD,
  getOrganizationJsonLD,
  getCustomPageJsonLD,
  getArticleJsonLD,
  getBreadcrumbJsonLD,
} from 'lib/jsonLD/jsonLDhelper';
import { LINKED_DATA_SCHEMA } from 'lib/constants/jsonld';

export const DEFAULT_TITLE =
  'FINANZCHECK - Der Online-Vergleich für optimale Kredite';
export const DEFAULT_DESCRIPTION =
  'FINANZCHECK - Das TÜV geprüfte Onlineportal für Ihren günstigen Kredit. Starten Sie noch heute Ihren Vergleich und finden Sie mit uns für sich das optimale Angebot.';
export const DEFAULT_DESCRIPTION_OPEN_GRAPH =
  'Deutschlands günstiger Onlinekredit im TÜV geprüften Kreditvergleich von FINANZCHECK.de';

interface MetaHeadProps {
  title?: string;
  description?: string;
  index?: boolean;
  follow?: boolean;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: Asset;
  markupSchema?: string;
  pageJsonLD?: any;
  url: string;
  datePublished?: string;
  dateModified?: string;
}

export const MetaHead = (props: MetaHeadProps) => {
  const {
    url,
    title,
    description,
    index = false,
    follow = false,
    ogTitle,
    ogDescription,
    ogImage,
    pageJsonLD,
    markupSchema = LINKED_DATA_SCHEMA.none,
    datePublished = new Date().toISOString(),
    dateModified = new Date().toISOString(),
  } = props;

  // We ONLY allow indexing on production. This is to prevent indexing of
  // staging environments and other non-production environments.
  // This sucks for testing, but the danger is too real.
  const environment = process.env.STAGE_ENV || 'shared';
  const isIndexingAllowed = environment === 'live';
  const indexString = index && isIndexingAllowed ? 'index' : 'noindex';
  const followString = follow && isIndexingAllowed ? 'follow' : 'nofollow';

  const FALLBACK_OG_IMAGE_URL =
    'https://images.ctfassets.net/f6c1mtmq4qrb/46mLp4xfuStXSiqhU0rq6j/98785f0d14e1f5f4d2d5e2b18f6fe970/Kredit_Original_1200x630.jpg';
  const FALLBACK_OG_IMAGE_WIDTH = 1200;
  const FALLBACK_OG_IMAGE_HEIGHT = 630;

  const ogImageFile = ogImage?.fields.file as AssetFile;
  const ogImageUrl = ogImageFile
    ? `https:${ogImageFile?.url}`
    : FALLBACK_OG_IMAGE_URL;
  const ogImageWidth =
    ogImageFile?.details?.image?.width || FALLBACK_OG_IMAGE_WIDTH;
  const ogImageHeight =
    ogImageFile?.details?.image?.height || FALLBACK_OG_IMAGE_HEIGHT;

  const includedJsonLDs = getJsonLDsForSchema(
    index ? markupSchema : LINKED_DATA_SCHEMA.none,
  );

  const urlSegments = url.split('/');
  const topLevelSiloLevel = 1;
  const articleSection = urlSegments[topLevelSiloLevel];

  return (
    <Head>
      <title>{title || DEFAULT_TITLE}</title>

      <meta name="description" content={description || DEFAULT_DESCRIPTION} />

      <meta property="og:title" content={ogTitle || DEFAULT_TITLE} />
      <meta
        property="og:description"
        content={ogDescription || DEFAULT_DESCRIPTION_OPEN_GRAPH}
      />
      <meta property="og:image" content={ogImageUrl} />
      <meta property="og:image:width" content={ogImageWidth.toString()} />
      <meta property="og:image:height" content={ogImageHeight.toString()} />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={typeof window !== 'undefined' ? window.location.href : ''}
      />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={ogTitle || title} />
      <meta name="twitter:site" content="@finanzcheck_de" />
      <meta name="twitter:account_id" content="358126175" />
      <meta name="twitter:creator" content="@finanzcheck_de" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/static/images/favicons/apple-touch-icon.png?v=9a7b59"
      />
      <link
        rel="icon"
        type="image/x-icon"
        href="/static/images/favicons/favicon.ico"
      />
      <link
        rel="icon"
        type="image/png"
        href="/static/images/favicons/favicon-32x32.png?v=9a7b59"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="/static/images/favicons/favicon-16x16.png?v=9a7b59"
        sizes="16x16"
      />
      <link
        rel="mask-icon"
        href="/static/images/favicons/safari-pinned-tab.svg"
        color="#165297"
      />
      <meta name="apple-mobile-web-app-title" content="Finanzcheck" />
      <meta name="application-name" content="Finanzcheck" />
      <link rel="manifest" href="/static/manifest.json" />

      <meta name="robots" content={`${indexString}, ${followString}`} />

      {includedJsonLDs.organization && (
        <script
          id="jsonld-organization"
          type="application/ld+json"
          dangerouslySetInnerHTML={getOrganizationJsonLD()}
        />
      )}
      {includedJsonLDs.website && (
        <script
          id="jsonld-website"
          type="application/ld+json"
          dangerouslySetInnerHTML={getWebsiteJsonLD()}
        />
      )}
      {includedJsonLDs.breadcrumb && (
        <script
          id="jsonld-breadcrumb"
          type="application/ld+json"
          dangerouslySetInnerHTML={getBreadcrumbJsonLD(url)}
        />
      )}
      {includedJsonLDs.article && (
        <script
          id="jsonld-article"
          type="application/ld+json"
          dangerouslySetInnerHTML={getArticleJsonLD(
            url,
            articleSection,
            title || DEFAULT_TITLE,
            'Patrick Reuter',
            dateModified,
            datePublished,
          )}
        />
      )}
      {pageJsonLD && (
        <script
          id="jsonld-custom"
          type="application/ld+json"
          dangerouslySetInnerHTML={getCustomPageJsonLD(pageJsonLD)}
        />
      )}
    </Head>
  );
};
